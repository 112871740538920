.patents2 {
    background-color: #000808;
    padding: 40px;
    overflow: hidden; 
    color: #f0f8ff;
}

.patents {
    margin-top: 0px;
    padding: 10px 60px 60px 60px;
    overflow: auto;
    display: flex;
    flex-direction: column;  /* To stack children vertically */
    align-items: center;     /* Horizontally align children to center */
    justify-content: center;
    text-align: justify;
    color: #f0f8ff;
}
.Meera{
     width: 100%;
    height: 100%;
    background-color: #cbe4f5;
    color: #010a0a;
    padding: 1%;
}
.Meera a{
    text-decoration:none;
    color: #010a0a;
}
.Meera p{
    font-size: 80%;
}
.Meera h6{
font-size: 90%;
    font-weight: bold;
}
.Meera .fields{
font-style: italic;
}
@media only screen and (max-width: 700px) {

    .Meera p{
        font-size: 70%;
    }
    .Meera h3{
        font-size: 100%;
    }
    .Meera h6{
        font-size: 70%;
            font-weight: bold;
        }
}
@media only screen and (max-width: 500px) {

    .Meera p{
        font-size: 60%;
    }
    .Meera h3{
        font-size: 80%;
    }
    .Meera h6{
    font-size: 60%;
        font-weight: bold;
    }
}