.about {
    margin-top: 0px;
    padding: 40px;
    overflow: auto;
    display: flex;
    flex-direction: column;  /* To stack children vertically */
    align-items: center;     /* Horizontally align children to center */
    justify-content: center;
}

hr {
    width: 60px;
    border: 2px solid black;
  
  }
  
h1 {
    font-size: 36px;
    margin: 0px;
    padding-top: 0px;
}

.content {
    display: flex;
    margin: 10px 50px 0% 50px;
}

.text-section {
    flex: 80%;
    text-align: justify;
    line-height: 1.5;
}

img {
    
    width: 250px;
    height: 250px;
    border-radius: 50%;
}

.image {
    float: left;
    display: flex;
  justify-content: center;
  flex-direction: column;  /* To stack children vertically */
  align-items: center;   
    flex: 40%;
    padding: 0px;
    padding-top: 0px;
}


.highlight {
    color: #ad9601;
    font-weight: bold;
    text-decoration: None;
}

.highlight:hover {
    color: #cecb66;
    transition: color 1s ease-in-out;
}

.text-status {
    color: #822f0e;
    font-weight: bold;
    display: flex;
    margin-left: 0px;
    padding-left:0px;
    justify-content: space-around;
    font-style: italic;
}


.resume {
    margin: 10px;
    width: 150px;
    font-size: 10px;
}
.test{
    text-align: center;
}
.button {
    color: rgb(247, 244, 243);
    background-color:rgb(10, 3, 1);
    border: 2px solid rgb(4, 4, 95);
    display: inline-block;
    margin: 10px;
    width: 150px;
    font-size: 18px;
    padding: 1.5%;
}

.button:hover {
    color: rgb(171, 124, 95);
    border: 2px solid rgb(252, 252, 252);
}   

.button span:after {
    content: '\21d3';
    color: white;
    
}

.social {
    margin-top: -25px;
    width: 20%;   
    float: right;  
    display: flex;
    align-items: center; 
}

.social img {
    width: 40px;  /* or whatever size you prefer */
    height:40px;
    margin: 15px;
}

table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

table, th, td {
    border: 1px solid #1db01a;
    padding: 8px;
    text-align: left;
}

table th, table td {
    padding: 8px 12px;
    text-align: left;
    
}
td{
    font-size: 15px;
}
td img {
    max-width: 15px;  
    height: auto;
    vertical-align: middle;
}
.skill-highlight{
 font-style: italic;
 font-family: Arial, Helvetica, sans-serif;
 color:  #802921;
}
@media only screen and (max-width: 768px) {
    .content {
        flex-direction: column;
    }

    .image {
        float: none;
        padding-bottom: 20px;
        text-align: center; /* Center the image and the button */
    }

    .text-section {
        flex: 100%; /* Take full width */
    }
}
.about table td, .about table th {
    border: 1px solid #1db01a;
}
.skills-set {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    flex-direction: column;  /* To stack children vertically */
    align-items: left;   
}
.skills-set,text{
    font-family:'Verdana', sans-serif;
    font-weight: bold;
}
.skillss{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: row;  /* To stack children vertically */
    align-items: left;   
    margin-top: -10px;
    margin-bottom: 5px;
}

.skill-rectangle {
    background-color: #3498db; 
    color: #fff; 
    padding: 6px 16px; 
    border-radius: 10px; 
    margin: 3px; 
    text-align: center;
    font-size: 70%; 
}
.personal{
    font-family:'Verdana', sans-serif;
}
@media only screen and (max-width: 600px) {
    .about {
        margin-top: 0px;
        padding: 10px;
        overflow: auto;
        display: flex;
        flex-direction: column;  /* To stack children vertically */
        align-items: center;     /* Horizontally align children to center */
        justify-content: center;
    }
    
    img {
    
        width: 150px;
        height: 150px;
        border-radius: 50%;
    }
    .resume {
        margin: 10px;
        width: 15%;
        font-size: 50%;
    }
    .button {
        color: rgb(182, 115, 91);
        border: 2px solid rgb(4, 4, 95);
        display: inline-block;
        width: 110px;
        height: 50px;
        font-size: 12px;
        padding: 2%;
    }
    
    .content p{
        font-size: 70%;
    }
   .skills-set p{
    font-size: 60%;
   }
   .skill-rectangle {
    background-color: #3498db; 
    color: #fff; 
    padding: 6px 16px; 
    border-radius: 10px; 
    margin: 3px; 
    text-align: center;
    font-size: 35%; 
}
}