.button {
    font-family: 'Railway', sans-serif;
    background-color: transparent; 
    color: white;
    cursor: pointer;
    padding: 2%;
    box-shadow: none;
    transition: background 0.3s ease-in-out;
    outline: none;
    text-align: center;
    width: 160px;
    border: 2px solid white;
    border-radius: 2px;
}

.button:hover {
    color: rgb(238, 233, 233);
}
  
.button span {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.5s;
}

.button span:after {
    content: '\21d2';
    position: absolute;
    opacity: 0;
    top: 0;
    right: -20px;
    transition: 0.5s;
}
.button-text {
    font-size: 80%; 
}
/* .button:hover span {
    padding-right: 25px;
}

.button:hover span:after {
    opacity: 1;
    right: 0;
} */