* {
  scroll-behavior: smooth;
}

.App-heading {
  background-color: #080101;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height:100vh;
  color: white;
}

.Intro{
  font-size:150%;
  padding: 2%;
}
footer {
  background-color: #010b06;
  font-family: "Lucida Console", Monaco, monospace;
  font-size: 70%;
  padding: 1%;
}

footer a{
  color: white;
  text-decoration: none;
  transition: 0.25s ease-in-out;
  display: block;
  text-align: center;
}

footer a:hover {
  color: #744444
}
.greeting{
  font-size: 200%;
  padding: 2%;
}
.name{
  font-size: 120%;
  color: #b3eb7c;

}
ul li{
  font-size: 90%;
}
.life{
  display: flex;
  flex-direction: row;  /* To stack children vertically */
  align-items: center;     /* Horizontally align children to center */
  justify-content: center;

  color: #f0f8ff;
  background-color: #080101;
  
}
.life li {
  border-right: 2px solid #774242;  /* Adjust color as needed */
  display: flex;


  flex-direction: column;  /* To stack children vertically */
  align-items: center;   
 padding-left: 8px;
 padding-right: 8px;
}
.life li p{
  margin-bottom: 0px;
}

.life li:last-child {
  border-right: none;   /* Removes the border for the last item */
}
.life li img {
  display: block; /* Display the images as block elements */
  margin: 0 auto; /* Center the images horizontally */
  text-align: center;
}
.life .his-logos{
height: 70px;
width:70px;
}
.life .his-logos2{
  height: 70px;
  width:80px;
  }
@media only screen and (max-width: 690px) {
  ul li{
    font-size: 80%;
  }
  .life .his-logos{
    height: 50px;
    width:50px;
    }
    .life .his-logos2{
      height: 50px;
      width:60px;
      }
}
@media only screen and (max-width: 400px) {
  ul li{
    font-size: 70%;
  }
  .life .his-logos{
    height: 50px;
    width:50px;
    }
    .life .his-logos2{
      height: 50px;
      width:60px;
      }
}
