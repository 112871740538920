#contact {
    overflow: hidden;
    padding: 40px 20px;
    display: flex;
    flex-direction: column;  /* To stack children vertically */
    align-items: center;     /* Horizontally align children to center */
    justify-content: center;
}
h1{
    font-size: 36px;
}
.information {
    margin: auto;
    width: 100%;
    justify-content: space-evenly;
    text-align: center;
}

.information p{
    margin-left: 4%;
    margin-right:4%;
    margin-bottom:30px;
    line-height: 1.6;
    font-size: 90%;
}

.text-status {
    color: #822f0e;
    font-weight: bold;
    font-style: italic;
}
svg {
    width: 40px;
    height: 40px;
    margin: 10px 10px;
    fill: #000000;
}

svg:hover {
    fill: #515453;
}
.social {
    display: flex;
    flex-direction: row; 
    align-items: center; 
    justify-content: space-evenly;
}


@media only screen and (max-width: 500px) {
    svg {
        width: 30px;
        height: 30px;
        margin: 10px 10px;
        fill: #000000;
    }
    
}